<template>
  <div class="info">
    <div class="info_layout">
      <div class="list">
        <span>头像</span>
        <div class="sub_list">
          <img
            class="pic"
            :src="
              userInfo.userDetailEntity.profilePicture
                ? userInfo.userDetailEntity.profilePicture
                : require('@/assets/images/portrait_icon.png')
            "
            alt=""
          />
          <img src="../../assets/images/more_icon.png" alt="" />
          <van-uploader :after-read="afterRead" />
        </div>
      </div>
      <div class="list">
        <span>登录账号</span>
        <span>{{ userInfo.userEntity.username | Tel }}</span>
      </div>
      <router-link class="list" to="userData/name">
        <span>真实姓名</span>
        <div>
          <span>{{
            userInfo.userEntity.actualName
              ? userInfo.userEntity.actualName
              : "未设置"
          }}</span>
          <img src="../../assets/images/more_icon.png" alt="" />
        </div>
      </router-link>
      <router-link class="list" to="userData/changeTel">
        <span>更绑手机号</span>
        <div>
          <img src="../../assets/images/more_icon.png" alt="" />
        </div>
      </router-link>
    </div>
    <div class="info_layout">
      <router-link to="userData/password" class="list pwd_list">
        <span>登录密码</span>
        <img src="../../assets/images/more_icon.png" alt="" />
      </router-link>
    </div>
    <div class="info_layout">
      <div class="list">
        <span>账户类型</span>
        <span v-if="userType === 'CONSUMER'" @click="t">会员用户</span
        ><span v-if="userType === 'PURCHASE'">店长用户</span>
      </div>
      <!-- <div class="list">
        <span>证件信息</span>
        <img src="../../assets/images/more_icon.png" alt="" />
      </div> -->
      <p class="out" @click="LoginOut">退出登录</p>
    </div>
  </div>
</template>

<script>
import { getToken, setToken, removeToken } from "@/utils/auth";
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
  name: "user-info",

  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["userInfo", "userType"]),
  },
  filters: {
    Tel(val) {
      return val.substr(0, 3) + "****" + val.substr(7);
    },
  },
  created() {
    !getToken() && this.$router.replace("/");
  },

  methods: {
    LoginOut() {
      Dialog.confirm({
        // title: '',
        message: "确定退出登录？",
      })
        .then(() => {
          removeToken();
          this.$store.commit("user/LoginOut", "");
          this.Toast.success("退出登录成功");
          this.$router.replace("/");
        })
        .catch(() => {
          // on cancel
        });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器

      var formdata = new FormData();
      formdata.append("file", file.file);
      this.$API.file
        .imageUpload(formdata)
        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          this.updatedUser(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          this.Toast.fail(res.data.msg);
        });
    },
    t() {
      eruda.init();
    },
    async updatedUser(profilePicture) {
      let userInfo = JSON.parse(JSON.stringify(this.userInfo));

      delete userInfo.userDetailEntity.updateTime;
      delete userInfo.userDetailEntity.createTime;
      delete userInfo.userDetailEntity.delete;
      delete userInfo.userEntity.updateTime;
      delete userInfo.userEntity.createTime;
      delete userInfo.userEntity.delete;
      userInfo.userEntity.password = "";
      userInfo.userDetailEntity.profilePicture = profilePicture;
      await this.$API.user
        .updateUser({
          object: {
            id: userInfo.id,
            userDetailEntity: userInfo.userDetailEntity,
            userEntity: userInfo.userEntity,
          },
        })
        .then(async (res) => {
          // removeToken();
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          await this.$store.dispatch("user/updatedUserInfo");
          this.Toast.success("更改头像成功");
          if (this.$route.query.taskId) {
            await this.$store.dispatch(
              "user/taskFinished",
              this.$route.query.taskId
            );
            this.$router.go(-1);
          } else {
            await this.$store.dispatch("user/taskFinished");
          }
        })
        .catch((err) => {
          console.log(err);
          this.Toast.fail(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  background: #f5f5f5;
  position: absolute;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
}
.info_layout {
  background: #fff;
  // padding: 0 16px;
  position: relative;
}
.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid #f2f2f2;
  color: #333;
  position: relative;
  img {
    width: 6px;
    height: 11px;
    margin-left: 10px;
  }

  ::v-deep .van-uploader {
    position: absolute;
    right: 0;
    z-index: 1;
    .van-uploader__upload {
      width: 50px;
      height: 50px;
      margin: 0;
      background: none;
      .van-icon {
        display: none;
      }
    }
  }
}
.first_list {
  padding: 6px 0;
}
.list:last-child {
  border-bottom: none;
}
.sub_list {
  display: flex;
  align-items: center;
}
img.pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.pwd_list {
  background: #fff;
  margin: 10px 16px;
  border: none;
}
.out {
  position: absolute;
  bottom: -40px;
  left: 50%;
  font-size: 16px;
  color: red;
  margin-left: -32px;
}
</style>

<style></style>
